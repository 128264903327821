import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const ThoughtItems = () => {

  const thoughts = useStaticQuery(graphql`
  query {
    allContentfulBlogPost(sort: { order: DESC, fields: [publishDate] }) {
      edges {
        node {
          title
          id
          tag
          publishDate(formatString: "MMMM DD, YYYY")
          slug
          articleImage {
            fluid {
              src
            }
          }
        }
      }
    }
  }

`)

  const thoughtItem = thoughts.allContentfulBlogPost.edges.map(thought => {
    return (
      <Link key={thought.node.id} className="w-3/6 mb-8 md:w-2/6 lg:w-2/6 pr-4" to={`/thoughts/${thought.node.slug}`}>
        <div className="overflow-hidden justify-center hover:underline">
          <img className="h-auto" src={thought.node.articleImage.fluid.src} alt={thought.node.articleImage.description} />
          <div className="px-6 pt-4 text-center">
            <h4 className="text-base font-bold mb-1 md:text-xl">{thought.node.title}</h4>
          </div>
        </div>
        <p className="text-gray-700 text-base text-xs text-center">
          {thought.node.tag.map(category => {
            return <span key={category} className="mr-3 italic">{category}</span>
          })}
        </p>
      </Link>
    )
  })

  return (
    <section id="all-thoughts" className="mb-8">
      <div className="flex flex-wrap mt-3">{thoughtItem}</div>
    </section>
  )
}

export default ThoughtItems
