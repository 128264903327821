import React from 'react'

import BreadCrumb from '../components/breadCrumb/index'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ThoughtItems from '../components/thoughtsOn/thoughtItems'


const crumbs = [
  {
    depth: '1',
    url: '/',
    title: 'Home',
    styles: '',
  },
]

const Thoughts = () => {
  return (
    <Layout>
      <SEO
        keywords={[`UX`, `Freelance`, `Designer`, `Researcher`]}
        title="Thoughts on"
      />
      <BreadCrumb crumbs={crumbs} />
      <section className="flex flex-col md:flex-row items-center">
        <section id="about-summary" className="md:w-2/3 md:mr-8 mb-3">
          <h2 className="text-4xl font-bold inline-block mb-3">
            Thoughts on ...
          </h2>
          <h3 className="text-2xl block mb-3">
            A collection of talks and articles I've written.
          </h3>
        </section>
      </section>
      <ThoughtItems />
    </Layout>
  )
}

export default Thoughts
